import { Button, Modal, Card, Col, DatePicker, Descriptions, Dropdown, Empty, Form, FormInstance, Input, InputNumber, List, MenuProps, message, Radio, Row, Skeleton, Space, Tag, Tooltip, Upload, Alert, Select } from "antd";
import moment from "moment";
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";
import { ExclamationCircleFilled, DownOutlined, UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
const { confirm } = Modal;

const ApplyOrderCreate = () => {
    const formRef = useRef<FormInstance>(null);
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 5 });
    const [state, setState] = useState<any>(-1);
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [list, setList] = useState<any[]>([]);
    const [bookingID, setBookingID] = useState<any>(null);
    const [applyType, setApplyType] = useState(0);
    const [validTruckNO, setValidTruckNO] = useState(true);
    const [validTransportNO, setValidTransportNO] = useState(true);
    useEffect(() => {
        loadData(false);
        formRef.current?.resetFields();
    }, []);

    const loadData = (isMore: boolean) => {
        setBookingID(null);
        setLoading(true);
        var params = {
            searchValue: searchValue,
            state: state,
            pageIndex: isMore ? page.pageIndex + 1 : 1,
            pageSize: page.pageSize
        }
        Server.Post(URL.ApplyAllocation.QueryApplyAllocationList, params, true)
            .then((response: any) => {
                if (response.isSuccess) {
                    if (isMore) {
                        setList(
                            data.concat([...response.data].map((item: any) => ({ loading: true, ...item }))),
                        );
                        const newData = data.concat(response.data);
                        setData(newData);
                        setList(newData);

                        setPage({
                            pageIndex: page.pageIndex + 1,
                            pageSize: page.pageSize
                        })
                    } else {
                        setData(response.data);
                        setList(response.data);

                        setPage({
                            pageIndex: 1,
                            pageSize: page.pageSize
                        })
                    }
                    setTotal(response.total);
                    setLoading(false);
                    setInitLoading(response.data.length > 0 ? false : true);
                    window.dispatchEvent(new Event('resize'));
                } else {
                    message.warning(response.returnMsg);
                }
            })
    }

    const onLoadMore = () => {
        loadData(true);
    };

    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>加载更多</Button>
            </div>
        ) : null;

    const onMenuClick: MenuProps['onClick'] = (e) => {
        console.log('click', e);
        if (e.key == '1') {
            downloadFile();
        }
    };

    const items = [
        {
            key: '1',
            label: '下载模板',
            icon: <DownOutlined />
        },
        {
            key: '2',
            label: <>
                <Upload showUploadList={false} onChange={(info: any) => {
                    // if (info.file.status !== 'uploading') {
                    //     alert('导入中')
                    // }
                    // if (info.file.status === 'done') {
                    //     alert('导入成功')
                    // } else if (info.file.status === 'error') {
                    //     alert('导入失败')
                    // }
                }} maxCount={1}>
                    申报导入
                </Upload>
            </>,
            icon: <UploadOutlined />
        }
    ];

    const downloadFile = () => {
        var reuqest = {};
        axios.post(URL.ApplyOrder.DownLoadTemplate, reuqest, {
            'responseType': 'blob'  //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置！！！
        }).then(function (response) {
            console.log(response);
            const blob = new Blob([response.data]);
            const fileName = '批量申报模板.xlsx';
            const linkNode = document.createElement('a');
            linkNode.download = fileName; //a标签的download属性规定下载文件的名称
            linkNode.style.display = 'none';
            linkNode.href = global.URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击
            global.URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
        }).catch(function (error) {
            console.log(error);
        });
    }

    const saveApplyOrder = () => {
        formRef.current?.validateFields().then((values => {
            if (values.amount <= 0) {
                message.warn('保额金必须大于0');
                return;
            }
            var params = {
                ...values,
                applyOrderNO: '',
                applyTypeEnum: applyType,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: moment(values.applyTime).utcOffset(0, true),
                requiredTime: moment(values.requiredTime).utcOffset(0, true),
                carrierTime: moment(values.carrierTime).utcOffset(0, true),
            };
            Server.Post(URL.ApplyOrder.SaveApplyOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    setBookingID(null)
                    confirm({
                        title: '查看申报订单',
                        icon: <ExclamationCircleFilled />,
                        content: <>
                            <p>{'申报订单[' + response.applyOrderNO + ']已暂存成功，需要提交后邮件至保司，是否立即跳转查看详情？'}</p>
                            <p>立即跳转：跳转查看申报详情</p>
                            <p>继续申报：放弃跳转，可至申报记录列表中查询申报订单，可修改或提交后邮件至保司</p>
                        </>,
                        okText: '立即跳转',
                        cancelText: '继续申报',
                        onOk() {
                            window.location.href = "/admin/orderdetail?oid=" + response.applyOrderID;
                        },
                        onCancel() {
                            loadData(false);
                        },
                    });
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    const submitApplyOrder = () => {
        formRef.current?.validateFields().then((values => {
            if (values.amount <= 0) {
                message.warn('保额金必须大于0');
                return;
            }
            var params = {
                ...values,
                applyOrderNO: '',
                applyTypeEnum: applyType,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: moment(values.applyTime).utcOffset(0, true),
                requiredTime: moment(values.requiredTime).utcOffset(0, true),
                carrierTime: moment(values.carrierTime).utcOffset(0, true),
            };
            Server.Post(URL.ApplyOrder.SaveAndSubmitApplyOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    setBookingID(null)
                    confirm({
                        title: '查看申报订单',
                        icon: <ExclamationCircleFilled />,
                        content: <>
                            <p>{'申报订单[' + response.applyOrderNO + ']已生成并邮件提交至保司，立即跳转查看详情？'}</p>
                            <p>立即跳转：跳转查看申报详情</p>
                            <p>继续申报：放弃跳转，可至申报记录列表中查询申报订单，可修改或提交后邮件至保司</p>
                        </>,
                        okText: '立即跳转',
                        cancelText: '继续申报',
                        onOk() {
                            window.location.href = "/admin/orderdetail?oid=" + response.applyOrderID;
                        },
                        onCancel() {
                            loadData(false);
                        },
                    });
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    useEffect(() => {
        setPage({ pageIndex: 1, pageSize: page.pageSize })
        loadData(false);
    }, [state])

    const cancelBooking = () => {
        confirm({
            title: '取消申报填写',
            icon: <ExclamationCircleFilled />,
            content: <>
                <p>{'确定取消申报填写？'}</p>
                <p>是：放弃申报填写内容</p>
                <p>否：继续填写申报内容</p>
            </>,
            okText: '是',
            cancelText: '否',
            onOk() {
                setBookingID(null)
            },
            onCancel() {
            },
        });
    }

    return <div>
        <div>
            <span><Radio.Group defaultValue={state} value={state} buttonStyle="solid"
                onChange={(e) => {
                    setState(e.target.value);
                }}>
                <Radio.Button value={-1}>全部{state == -1 ? '(' + total + ')' : ''}</Radio.Button>
                <Radio.Button value={0}>已生效{state == 0 ? '(' + total + ')' : ''}</Radio.Button>
                <Radio.Button value={1}>已过期{state == 1 ? '(' + total + ')' : ''}</Radio.Button>
            </Radio.Group></span>
            <Space style={{ float: 'right' }}>
                <Button type='primary' onClick={() => { loadData(false) }}>查询</Button>
            </Space>
        </div>
        <List
            itemLayout="horizontal"
            dataSource={list}
            loadMore={loadMore}
            locale={{ emptyText: '暂无保单数据，请联系管理员签约保单' }}
            renderItem={(item) => (
                <List.Item>
                    <Skeleton title={false} loading={item.loading} active>
                        <List.Item.Meta
                            title={
                                <div style={{ marginBottom: '15px' }}>
                                    <span>
                                        <Space size={'small'}>
                                            <a onClick={() => { window.location.href = '/admin/allocationdetail?oid=' + item.applyAllocationID; }}>{"保单编码：" + item.applyAllocationNO}</a>
                                            <span>{"协议号：" + item.agreementNO}</span>
                                            <>
                                                {
                                                    item ? (item.tags).map((item: any) => {
                                                        return <Tag color={item.color}>{item.name}</Tag>
                                                    }) : ''
                                                }
                                            </>
                                        </Space>
                                    </span>
                                    <span style={{
                                        float: 'right',
                                        display: (moment(item.expireTime) < moment() || item.usedAmount >= item.amount) ? 'none' : 'block'
                                    }}>
                                        <Space>
                                            <Button hidden={item.applyAllocationID == bookingID}
                                                type='dashed'
                                                onClick={() => {
                                                    window.location.href = '/admin/allocationDetail?oid=' + item.applyAllocationID;
                                                }}>保单详情</Button>
                                            <Button type='primary' hidden={item.applyAllocationID == bookingID}
                                                onClick={() => setBookingID(item.applyAllocationID)}>立即申报</Button>
                                            {
                                                item.applyAllocationID != bookingID ?
                                                    <Dropdown.Button icon={<DownOutlined />} menu={{ items, onClick: onMenuClick }}>
                                                        {'批量申报'}
                                                    </Dropdown.Button>
                                                    : <></>
                                            }
                                        </Space>
                                    </span>
                                </div>
                            }
                            description={
                                <>
                                    <div style={{ marginBottom: '5px' }}>
                                        <Card size="small" bordered={false} bodyStyle={{ padding: '0' }} >
                                            <Descriptions layout="horizontal" bordered size="small"
                                                style={{ marginBottom: '5px' }}
                                                column={4}>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保人" >{item.insureBy}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="被保人" >{item.insuredBy}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="保险公司" >{item.companyName}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="产品名称" >{item.applyProductName}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="生效日期" >{moment(item.effectTime).format('YYYY-MM-DD')}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="失效日期" >{moment(item.expireTime).format('YYYY-MM-DD')}</Descriptions.Item>
                                                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保限额" >{item.singleLimit}{'万元'}</Descriptions.Item>
                                                <Descriptions.Item labelStyle={{ width: '90px' }} label="总保额" contentStyle={{ fontWeight: 'bold' }}>{item.amount}{'万元'}</Descriptions.Item>
                                                <Descriptions.Item labelStyle={{ width: '90px' }} label="剩余保额" contentStyle={{ fontWeight: 'bold', color: 'green' }}>{item.amount - item.usedAmount}{'万元'}</Descriptions.Item>
                                                <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="免赔额" >
                                                    <TextArea autoSize bordered={false} readOnly value={item.deductibleAmountDesc} />
                                                </Descriptions.Item>
                                                <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="承保区域" >{item.insureArea}</Descriptions.Item>
                                            </Descriptions>
                                        </Card>
                                    </div>
                                    <div style={{ margin: '0px', display: item.applyAllocationID == bookingID ? 'block' : 'none' }} >
                                        {item.applyAllocationID == bookingID ? <Card title={'申报填写'}
                                            style={{ borderStyle: 'dashed', borderColor: '#1890ff' }}
                                            size='small' extra={
                                                <Space>
                                                    <Tooltip placement="top" title={<>
                                                        <p>取消填写:关闭申报填写面板并放弃填写内容</p>
                                                    </>}>
                                                        <Button danger onClick={() => cancelBooking()}>取消填写</Button>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title={<>
                                                        <p>暂存申报:保存申报填写内容，但不会提交给保司，任然可以进行内容修改</p>
                                                    </>}>
                                                        <Button onClick={() => saveApplyOrder()}>暂存申报</Button>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title={<>
                                                        <p>提交申报:保存申报填写内容，并立即邮件形式提交至保司</p>
                                                    </>}>
                                                        <Button type={'primary'}
                                                            onClick={() => submitApplyOrder()}>提交申报</Button>
                                                    </Tooltip>
                                                </Space>
                                            }
                                        >
                                            <Form ref={formRef}
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}>
                                                <Form.Item hidden label={'申报保单ID'} name={'applyAllocationID'} initialValue={bookingID}>
                                                    <Input readOnly />
                                                </Form.Item>
                                                <Form.Item hidden label={'保单编码'} name={'applyAllocationNO'} initialValue={item.applyAllocationNO}>
                                                    <Input readOnly />
                                                </Form.Item>
                                                <Form.Item hidden label={'申报订单ID'} name={'applyOrderID'} initialValue={null}>
                                                    <Input readOnly />
                                                </Form.Item>
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }}
                                                            label={'申报时间'} name={'applyTime'}
                                                            initialValue={moment()} rules={[{ required: true, message: '请选择申报时间' }]}>
                                                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                                                onChange={(e) => { }}
                                                                defaultValue={moment()}
                                                                format="YYYY-MM-DD"
                                                                style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px', width: '100%' }} label={'申报类型'}
                                                            initialValue={0} rules={[{ required: true, message: '请选择申报类型' }]}>
                                                            <Tooltip placement="top" title={<>
                                                                <p>即时申报:提交申报则立即向保司发送邮件</p>
                                                                <p>延迟申报:提交申报后在约定时间申报设置的时间向保司发送邮件</p>
                                                            </>}>
                                                                <Radio.Group options={[
                                                                    { label: '即时申报', value: 0 },
                                                                    { label: '约定时间申报', value: 1 }
                                                                ]} onChange={(item) => {
                                                                    setApplyType(item.target.value)
                                                                }}
                                                                    value={applyType} optionType="button" buttonStyle="solid" />
                                                            </Tooltip>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}
                                                            hidden={applyType == 0} style={{ marginBottom: '4px' }} label={'指定时间'}
                                                            name={'requiredTime'} initialValue={moment()} rules={[{ required: true, message: '指定申报时间' }]}>
                                                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                                                onChange={(e) => { }}
                                                                showHour={true}
                                                                showMinute={true}
                                                                showTime
                                                                defaultValue={moment()}
                                                                format="YYYY-MM-DD HH:mm"
                                                                style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'起运时间'}
                                                            name={'carrierTime'} initialValue={moment()} rules={[{ required: true, message: '请选择起运时间' }]}>
                                                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                                                onChange={(e) => { }}
                                                                defaultValue={moment()}
                                                                format="YYYY-MM-DD"
                                                                style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'货物品名'}
                                                            name={'partName'} initialValue={''} rules={[{ required: true, message: '请填写货物品名' }]}>
                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'货物包装'}
                                                            name={'packages'} initialValue={''} rules={[{ required: true, message: '请填写货物包装' }]}>
                                                            <Select
                                                                style={{ width: '100%' }}
                                                                options={[
                                                                    { value: '散装及托盘包装', label: '散装及托盘包装' },
                                                                    { value: '捆包状包装', label: '捆包状包装' },
                                                                    { value: '袋状包装', label: '袋状包装' },
                                                                    { value: '箱状包装', label: '箱状包装' },
                                                                    { value: '桶状包装', label: '桶状包装' },
                                                                    { value: '裸状包装', label: '裸状包装' },
                                                                    { value: '其他形状包装', label: '其他形状包装' }
                                                                ]} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'货物件数'}
                                                            name={'eaQty'} initialValue={''} rules={[{ required: true, message: '请填写货物件数' }]}>
                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'始发地'}
                                                            name={'startAddress'} initialValue={''} rules={[{ required: true, message: '请填写始发地' }]}>
                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'目的地'}
                                                            name={'destAddress'} initialValue={''} rules={[{ required: true, message: '请填写目的地' }]}>
                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'车牌号'}
                                                            name={'truckNO'} initialValue={''} rules={[{ required: validTruckNO, message: '请填写车牌号' }]}>
                                                            <Input style={{ width: '100%' }} onChange={(e) => {
                                                                if (e.target.value && e.target.value.trim() != '') {
                                                                    setValidTransportNO(false);
                                                                } else {
                                                                    setValidTransportNO(true);
                                                                }
                                                            }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'运单号'}
                                                            name={'transportNO'} initialValue={''} rules={[{ required: validTransportNO, message: '请填写运单号' }]}>
                                                            <Input style={{ width: '100%' }} onChange={(e) => {
                                                                if (e.target.value && e.target.value.trim() != '') {
                                                                    setValidTruckNO(false);
                                                                } else {
                                                                    setValidTruckNO(true);
                                                                }
                                                            }} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={18}>
                                                        <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 24 }}
                                                            name={'remark'} style={{ marginBottom: '4px' }} label={'说明'} initialValue={''} >
                                                            <Input style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item style={{ marginBottom: '4px' }} label={'保额'}
                                                            name={'insureAmount'} initialValue={'1'}
                                                            rules={[{ required: true, message: '请填写保额' }]}>
                                                            <InputNumber min={1} max={item.singleLimit} style={{ width: '100%' }} addonBefore={'限额' + item.singleLimit + '万'} addonAfter={'万元'} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={18} style={{ paddingLeft: '80px' }}>
                                                        {/* <Form.Item wrapperCol={{ span: 24 }}
                                                            style={{ marginBottom: '4px' }} label={''} initialValue={''} >
                                                            <Upload style={{ float: 'left' }}
                                                                {...{
                                                                    onChange({ file, fileList }) {
                                                                        const formData = new FormData();
                                                                        fileList.forEach((fileItem: any) => {
                                                                            formData.append('files[]', fileItem);
                                                                        });
                                                                        var reuqest = {};
                                                                        axios.post(URL.ApplyOrder.UpLoadFiles, reuqest, {
                                                                            headers: {
                                                                                'Content-Type': 'application/json',
                                                                                'Accept': 'application/json'
                                                                            }
                                                                        }).then(function (response) {
                                                                            console.log(response);
                                                                        }).catch(function (error) {
                                                                            console.log(error);
                                                                        });
                                                                    },
                                                                    maxCount: 4,
                                                                    multiple: true,
                                                                    defaultFileList: [
                                                                    ],
                                                                }}>
                                                                <Button icon={<UploadOutlined />}>附件上传(最多4个)</Button>
                                                            </Upload>
                                                        </Form.Item> */}
                                                    </Col>
                                                    <Col span={6} >
                                                        <Alert style={{ float: 'right' }}
                                                            banner type='warning'
                                                            message="温馨提醒：不足额投保会有比例赔付!" />
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card> : <></>}
                                    </div>
                                </>
                            }
                        />
                    </Skeleton>
                </List.Item >
            )}
        />
    </div >
}
export default ApplyOrderCreate;