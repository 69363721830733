let BaseUrl = '/api/';
let UserUrl = BaseUrl + "User/";
let ProductUrl = BaseUrl + "Product/";
let ApplyAllocationUrl = BaseUrl + "ApplyAllocation/";
let ApplyOrderUrl = BaseUrl + "ApplyOrder/";
let ApplyConsumeUrl = BaseUrl + "ApplyConsume/";
let ApplyEmailUrl = BaseUrl + "ApplyEmail/";

const URL = {
    User: {
        UserLogin: UserUrl + 'UserLogin',
        Register: UserUrl + 'Register',
        ResetPwd: UserUrl + 'ResetPwd',
        GetMsgCode: UserUrl + 'GetMsgCode',
        QueryUser: UserUrl + 'QueryUser',
        UpdateName: UserUrl + 'UpdateName',
    },
    Product: {
        QueryProductList: ProductUrl + 'QueryProductList',
        QueryCompanyAddition: ProductUrl + 'QueryCompanyAddition',
        QueryProductAddition: ProductUrl + 'QueryProductAddition',
        GetCompanyList: ProductUrl + 'GetProductCompanyList'
    },
    ApplyAllocation:{
        QueryApplyAllocationList: ApplyAllocationUrl + 'QueryApplyAllocationList',
        QueryApplyAllocation: ApplyAllocationUrl + 'QueryApplyAllocation',
    },
    ApplyOrder: {
        QueryApplyOrderList: ApplyOrderUrl + 'QueryApplyOrderList',
        QueryAllocationApplyOrderList: ApplyOrderUrl + 'QueryAllocationApplyOrderList',
        QueryApplyOrder: ApplyOrderUrl + 'QueryApplyOrder',
        SaveApplyOrder: ApplyOrderUrl + 'SaveApplyOrder',
        SaveAndSubmitApplyOrder: ApplyOrderUrl + 'SaveAndSubmitApplyOrder',
        SubmitApplyOrder: ApplyOrderUrl + 'SubmitApplyOrder',
        DeleteApplyOrder: ApplyOrderUrl + 'DeleteApplyOrder',
        DownLoadTemplate: ApplyOrderUrl + 'DownLoadTemplate',
        UpLoadFiles: ApplyOrderUrl + 'UpLoadFiles',
    },
    ApplyConsume: {
        GetApplyConsumeList: ApplyConsumeUrl + 'GetApplyConsumeList',
    },
    ApplyEmail: {
        GetApplyEmailList: ApplyEmailUrl + 'GetApplyEmailList',
    }
}

export default URL;