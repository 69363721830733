import { Avatar, Badge, Breadcrumb, Dropdown, Layout, Menu, Modal, Space } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import * as React from 'react';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';
import '../../styles/main.css';
import {
    MessageOutlined,
    UserOutlined,
    InfoCircleOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import Error404 from '../error/Error404';
import Home from '../admin/home/Home';
import ApplyOrder from '../admin/apply/applyOrder/ApplyOrder';
import Setting from '../admin/setting/Setting';
import ApplyOrderCreate from '../admin/apply/applyOrder/ApplyOrderCreate';
import ApplyAllocationDetail from '../admin/apply/applyAllocation/ApplyAllocationDetail';
import ApplyOrderDetail from '../admin/apply/applyOrder/ApplyOrderDetail';

const Main = () => {
    const [isModalOpen, setisModalOpen] = React.useState(false);
    const [loginOutVisible, setloginOutVisible] = React.useState(false);
    const [confirmLoading, setconfirmLoading] = React.useState(false);
    const [userInfo, setUserInfo] = React.useState<any>(null);
    const [selectedKeys, setselectedKeys] = React.useState<any>([]);
    const [menuItems, setMenuItems] = React.useState<Array<any>>([])

    React.useEffect(() => {

        let etokenKey = process.env.REACT_APP_TOKEN ?? '';
        var token = localStorage.getItem(etokenKey);
        if (!token) {
            window.location.href = '/login';
        } else {
            var userInfo = localStorage.getItem('EKnown.UserInfo');
            if (userInfo) {
                var user = JSON.parse(userInfo);
                setUserInfo(user);
                setMenuItems([
                    { key: '1', label: <Link to='/admin/apply'>立即申报</Link> },
                    { key: '2', label: <Link to='/admin/order'>申报记录</Link> },
                    { key: '3', label: <Link to='/admin/setting'>个人设置</Link> }]);
            }
        }
    }, [])

    React.useEffect(() => {
        var filterItem = menuItems.find(m => m.label.props.to == window.location.pathname);
        if (filterItem) {
            setselectedKeys([filterItem.key]);
        } else {
            if (menuItems.length > 0) {
                setselectedKeys([menuItems[0].key]);
            }
        }
    }, [menuItems])

    const loginOutOK = () => {
        try {
            setconfirmLoading(true);
            localStorage.clear();
            window.location.href = '/login';
        } finally {
            setloginOutVisible(false)
            setconfirmLoading(false);
        }
    }

    const loginOutCancel = () => {
        setloginOutVisible(false)
    }

    const menu = (
        <Menu>
            <Menu.Item placeholder='bottomCenter' icon={<UserOutlined />}
                onClick={() => { setselectedKeys(['']); window.location.href = '/admin/setting'; }} >
                个人设置
            </Menu.Item>
            <Menu.Item danger placeholder='bottomCenter'
                icon={<LogoutOutlined />}
                onClick={() => { setloginOutVisible(true) }}>注销</Menu.Item>
            <Modal
                title="注销登录"
                visible={loginOutVisible}
                onOk={loginOutOK}
                onCancel={loginOutCancel}
                okText='注销'
                cancelText='取消'
                confirmLoading={confirmLoading}
            >
                <p>确认注销用户 {userInfo?.name} 登录状态?</p>
            </Modal>
        </Menu >
    );

    return (
        <Layout style={{ height: '100%' }} className="layout">
            <Header style={{ width: '100%' }}>
                <span style={{ float: 'left', width: '320px', color: 'white' }}>
                    56报备平台
                </span>
                <div style={{ float: 'left' }}>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        defaultSelectedKeys={selectedKeys}
                        selectedKeys={selectedKeys}
                        onSelect={(item: any) => { setselectedKeys([item.key]) }}
                        items={menuItems}
                    />
                </div>
                <div style={{ float: 'right', fontSize: '18px' }}>
                    <Space size={'small'}>
                        <Dropdown overlay={menu} >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                <Avatar
                                    style={{
                                        backgroundColor: '#00a2ae',
                                        verticalAlign: 'middle',
                                    }}
                                    size='large'
                                    alt={userInfo?.name}
                                >{userInfo?.name}</Avatar>
                            </a>
                        </Dropdown>
                    </Space>
                </div>
            </Header>
            <Content className="site-layout" style={{
                padding: '8px 50px',
                overflowY: 'auto'
            }}>
                <div className="site-layout-background" style={{
                    padding: '24px 24px 44px 24px',
                    minHeight: '100%'
                }}>
                    <Routes>
                        {/* <Route path='/' element={<Home />} /> */}
                        <Route path='/home' element={<Home />} />
                        <Route path='/' element={<ApplyOrderCreate />} />
                        <Route path='/apply' element={<ApplyOrderCreate />} />
                        <Route path='/allocationDetail' element={<ApplyAllocationDetail />} />
                        <Route path='/orderDetail' element={<ApplyOrderDetail />} />
                        <Route path='/order' element={<ApplyOrder />} />
                        <Route path='/setting/*' element={<Setting />} />
                        <Route path='/*' element={<Error404 />} />
                    </Routes>
                </div>
            </Content>
        </Layout>
    )
};

export default Main;

