import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Radio, Row, Select, Space, Tag, Tooltip } from "antd";
import FormItem from "antd/es/form/FormItem";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
const { confirm } = Modal;

const ApplyOrderEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [record, setRecord] = useState<any>({});
    const [applyAllocation, setApplyAllocation] = useState<any>({});
    const [applyType, setApplyType] = useState(0);
    const [validTruckNO, setValidTruckNO] = useState(true);
    const [validTransportNO, setValidTransportNO] = useState(true);

    useEffect(() => {
        if (rest.isOpen) {
            loadApplyOrder();
            loadApplyAllocation();
        }
    }, [rest.isOpen])

    useEffect(() => {
        formRef.current?.resetFields();
        if (record) {
            formRef.current?.setFieldsValue({
                ...record
                , carrierTime: moment(record.carrierTime)
                , applyTime: moment(record.applyTime)
                , requiredTime: record.requiredTime ? moment(record.requiredTime) : moment(record.applyTime)
            });
            setValidTransportNO(record.truckNO && record.truckNO != '' ? false : true);
            setValidTruckNO(record.transportNO && record.transportNO != '' ? false : true);
        }
    }, [record])

    const loadApplyOrder = () => {
        var params = {
            applyOrderID: rest.applyOrderID
        }
        Server.Post(URL.ApplyOrder.QueryApplyOrder, params, false).then((response: any) => {
            if (response.isSuccess) {
                setRecord(response);
            }
        })
    }

    const loadApplyAllocation = () => {
        var params = {
            applyAllocationID: rest.applyAllocationID
        }
        Server.Post(URL.ApplyAllocation.QueryApplyAllocation, params, false).then((response: any) => {
            if (response.isSuccess) {
                setApplyAllocation(response);
            }
        })
    }

    const cancelBooking = () => {
        rest.setIsOpen(false);
    }
    const saveApplyOrder = () => {
        formRef.current?.validateFields().then((values => {
            if (values.amount <= 0) {
                message.warn('保额金必须大于0');
                return;
            }
            var params = {
                ...values,
                applyOrderNO: record.applyOrderNO,
                applyTypeEnum: applyType,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: moment(values.applyTime).utcOffset(0, true),
                requiredTime: moment(values.requiredTime).utcOffset(0, true),
                carrierTime: moment(values.carrierTime).utcOffset(0, true),
            };
            Server.Post(URL.ApplyOrder.SaveApplyOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    const submitApplyOrder = () => {
        formRef.current?.validateFields().then((values => {
            if (values.amount <= 0) {
                message.warn('保额金必须大于0');
                return;
            }
            var params = {
                ...values,
                applyOrderNO: record.applyOrderNO,
                applyTypeEnum: applyType,
                applyType: applyType == 0 ? '即时申报' : '延迟申报',
                applyTime: moment(values.applyTime).utcOffset(0, true),
                requiredTime: moment(values.requiredTime).utcOffset(0, true),
                carrierTime: moment(values.carrierTime).utcOffset(0, true),
            };
            Server.Post(URL.ApplyOrder.SaveAndSubmitApplyOrder, params).then((response: any) => {
                if (response.isSuccess) {
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }
    return <Modal title={"编辑申报订单"} width={1350} open={rest.isOpen} onCancel={cancelBooking}
        maskClosable={false}
        footer={
            <Space>
                <Tooltip placement="top" title={<>
                    <p>取消填写:关闭申报填写面板并放弃填写内容</p>
                </>}>
                    <Button danger onClick={() => cancelBooking()}>取消填写</Button>
                </Tooltip>
                <Tooltip placement="top" title={<>
                    <p>暂存申报:保存申报填写内容，但不会提交给保司，任然可以进行内容修改</p>
                </>}>
                    <Button onClick={() => saveApplyOrder()}>暂存申报</Button>
                </Tooltip>
                <Tooltip placement="top" title={<>
                    <p>提交申报:保存申报填写内容，并立即邮件形式提交至保司</p>
                </>}>
                    <Button type={'primary'}
                        onClick={() => submitApplyOrder()}>提交申报</Button>
                </Tooltip>
            </Space>
        }>
        <Card size="small" title={'保单信息'} type={'inner'} bordered={false} bodyStyle={{ padding: '0' }} >
            <Descriptions layout="horizontal" bordered size="small"
                style={{ marginBottom: '5px' }}
                column={4}>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="协议号" >{applyAllocation ? applyAllocation.agreementNO : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保人" >{applyAllocation ? applyAllocation.insureBy : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="被保人" >{applyAllocation ? applyAllocation.insuredBy : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="保险公司" >{applyAllocation ? applyAllocation.companyName : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="产品名称" >{applyAllocation ? applyAllocation.applyProductName : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="投保限额" >{applyAllocation ? applyAllocation.singleLimit : '200'}{'万元'}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="生效日期" >{applyAllocation ? moment(applyAllocation.effectTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
                <Descriptions.Item span={2} labelStyle={{ width: '90px' }} label="失效日期" >{applyAllocation ? moment(applyAllocation.expireTime).format('YYYY-MM-DD') : ''}</Descriptions.Item>
                <Descriptions.Item labelStyle={{ width: '90px' }} label="总保额" >{applyAllocation ? applyAllocation.amount : 0}{'万元'}</Descriptions.Item>
                <Descriptions.Item labelStyle={{ width: '90px' }} label="剩余保额" >{applyAllocation ? (applyAllocation.amount - applyAllocation.usedAmount) : 0}{'万元'}</Descriptions.Item>
                <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="免赔额" >
                    <TextArea autoSize bordered={false} readOnly value={applyAllocation ? applyAllocation.deductibleAmountDesc : ''} />
                </Descriptions.Item>
                <Descriptions.Item span={4} labelStyle={{ width: '90px' }} label="承保区域" >{applyAllocation ? applyAllocation.insureArea : ''}</Descriptions.Item>
            </Descriptions>
        </Card>
        <Divider />

        <Card size="small" title={
            <>
                <span >申报订单</span>
                <Space size={'small'} style={{ float: 'right' }}>
                    <>
                        {
                            record ? (record.tags ?? []).map((item: any) => {
                                return <Tag color={item.color}>{item.name}</Tag>
                            }) : ''
                        }
                    </>
                </Space>
            </>
        } type={'inner'} bordered bodyStyle={{ padding: '5px' }} >
            <Form ref={formRef}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}>
                <Form.Item hidden label={'申报分配ID'} name={'applyAllocationID'} initialValue={applyAllocation ? applyAllocation.applyAllocationID : ''}>
                    <Input readOnly />
                </Form.Item>
                <Form.Item hidden label={'申报分配单号'} name={'applyAllocationNO'} initialValue={applyAllocation ? applyAllocation.applyAllocationNO : ''}>
                    <Input readOnly />
                </Form.Item>
                <Form.Item hidden label={'申报订单ID'} name={'applyOrderID'} initialValue={record ? record.applyOrderID : null}>
                    <Input readOnly />
                </Form.Item>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }}
                            label={'申报时间'} name={'applyTime'}
                            initialValue={moment()} rules={[{ required: true, message: '请选择申报时间' }]}>
                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                onChange={(e) => { }}
                                defaultValue={moment()}
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px', width: '100%' }} label={'申报类型'}
                            initialValue={0} rules={[{ required: true, message: '请选择申报类型' }]}>
                            <Tooltip placement="top" title={<>
                                <p>即时申报:提交申报则立即向保司发送邮件</p>
                                <p>延迟申报:提交申报后在约定时间申报设置的时间向保司发送邮件</p>
                            </>}>
                                <Radio.Group options={[
                                    { label: '即时申报', value: 0 },
                                    { label: '约定时间申报', value: 1 }
                                ]} onChange={(item) => {
                                    setApplyType(item.target.value)
                                }}
                                    value={applyType} optionType="button" buttonStyle="solid" />
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }}
                            hidden={applyType == 0} style={{ marginBottom: '4px' }} label={'约定时间'}
                            name={'requiredTime'} initialValue={moment()} rules={[{ required: true, message: '请选择约定时间' }]}>
                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                onChange={(e) => { }}
                                showHour={true}
                                showMinute={true}
                                showTime
                                defaultValue={moment()}
                                format="YYYY-MM-DD HH:mm"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'起运时间'}
                            name={'carrierTime'} initialValue={moment()} rules={[{ required: true, message: '请选择起运时间' }]}>
                            <DatePicker disabledDate={(current) => { return current && current < moment().startOf('days'); }}
                                onChange={(e) => { }}
                                defaultValue={moment()}
                                format="YYYY-MM-DD"
                                style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'货物品名'}
                            name={'partName'} initialValue={''} rules={[{ required: true, message: '请填写货物品名' }]}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'货物包装'}
                            name={'packages'} initialValue={''} rules={[{ required: true, message: '请选择货物包装' }]}>
                            <Select
                                style={{ width: '100%' }}
                                options={[
                                    { value: '散装及托盘包装', label: '散装及托盘包装' },
                                    { value: '捆包状包装', label: '捆包状包装' },
                                    { value: '袋状包装', label: '袋状包装' },
                                    { value: '箱状包装', label: '箱状包装' },
                                    { value: '桶状包装', label: '桶状包装' },
                                    { value: '裸状包装', label: '裸状包装' },
                                    { value: '其他形状包装', label: '其他形状包装' }
                                ]} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'货物件数'}
                            name={'eaQty'} initialValue={''} rules={[{ required: true, message: '请填写货物件数' }]}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'始发地'}
                            name={'startAddress'} initialValue={''} rules={[{ required: true, message: '请填写始发地' }]}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'目的地'}
                            name={'destAddress'} initialValue={''} rules={[{ required: true, message: '请填写目的地' }]}>
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'车牌号'}
                            name={'truckNO'} initialValue={''} rules={[{ required: validTruckNO, message: '请填写车牌号' }]}>
                            <Input style={{ width: '100%' }} onChange={(e) => {
                                if (e.target.value && e.target.value.trim() != '') {
                                    setValidTransportNO(false);
                                } else {
                                    setValidTransportNO(true);
                                }
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'运单号'}
                            name={'transportNO'} initialValue={''} rules={[{ required: validTransportNO, message: '请填写运单号' }]}>
                            <Input style={{ width: '100%' }} onChange={(e) => {
                                if (e.target.value && e.target.value.trim() != '') {
                                    setValidTruckNO(false);
                                } else {
                                    setValidTruckNO(true);
                                }
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={18}>
                        <Form.Item labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}
                            name={'remark'} style={{ marginBottom: '4px' }} label={'说明'} initialValue={''} >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item style={{ marginBottom: '4px' }} label={'保额'}
                            name={'insureAmount'} initialValue={200} rules={[{ required: true, message: '请填写保额' }]}>
                            <InputNumber min={1} max={200} style={{ width: '100%' }} addonBefore={'限额' + (applyAllocation ? applyAllocation.singleLimit : '200') + '万'} addonAfter={'万元'} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    </Modal>
}
export default ApplyOrderEdit;