import { Badge, Button, Input, message, Modal, Radio, Space, Table, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import Server from "../../../../server/Server";
import URL from "../../../../server/URL";
import moment from 'moment';
import 'moment/locale/zh-cn';
import ApplyOrderEdit from "./ApplyOrderEdit";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const ApplyOrder = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [state, setState] = useState<any>(-1);
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: ColumnsType<any> = [
        {
            key: 'applyOrderNO', title: '申报单号', dataIndex: 'applyOrderNO', align: 'center'
            , fixed: 'left', ellipsis: true, width: 120,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/admin/orderdetail?oid=' + record.applyOrderID
            }}>{text}</a>
        }, {
            key: 'applyTime', title: '申报日期', dataIndex: 'applyTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD')
            )
        }, {
            title: '申报状态', dataIndex: 'state', align: 'center', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.stateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'applyType', title: '申报类型', dataIndex: 'applyType',
            align: 'center', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                text + (record.applyTypeEnum == 0 ? '' : '(' + moment(record.requiredTime).format('YYYY-MM-DD HH:mm') + ')')
            )
        }, {
            title: '发送状态', dataIndex: 'sendState', align: 'center', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.sendStateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'sendTime', title: '发送日期', dataIndex: 'sendTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , { key: 'agreementNO', title: '协议号', dataIndex: 'agreementNO', align: 'center', width: 100, ellipsis: true }, { key: 'partName', title: '货物名称', dataIndex: 'partName', align: 'center', width: 120 }
        , {
            key: 'carrierTime', title: '承运日期', dataIndex: 'carrierTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }
        , { key: 'packages', title: '货物包装', dataIndex: 'packages', align: 'center', width: 100, ellipsis: true }
        , { key: 'eaQty', title: '货物件数', dataIndex: 'eaQty', align: 'center', width: 100, ellipsis: true }
        , { key: 'startAddress', title: '始发地', dataIndex: 'startAddress', align: 'center', width: 100, ellipsis: true }
        , { key: 'destAddress', title: '目的地', dataIndex: 'destAddress', align: 'center', width: 100, ellipsis: true }
        , { key: 'truckNO', title: '车牌号', dataIndex: 'truckNO', align: 'center', width: 100, ellipsis: true }
        , { key: 'transportNO', title: '运单号', dataIndex: 'transportNO', align: 'center', width: 100, ellipsis: true }
        , {
            key: 'insureAmount', title: '保额', dataIndex: 'insureAmount', align: 'center'
            , width: 100, ellipsis: true, render: (text: string, record: any) => (
                text + '万元'
            )
        }
        , {
            key: 'applyAllocationNO', title: '保单编码', dataIndex: 'applyAllocationNO', align: 'center'
            , width: 100, ellipsis: true,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/admin/allocationdetail?oid=' + record.applyAllocationID;
            }}>{text}</a>
        },
        {
            key: 'createBy', title: '创建人', dataIndex: 'createBy', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'createTime', title: '创建时间', dataIndex: 'createTime', width: 100,
            align: 'center', ellipsis: true, render: (text, record) => (
                moment(text).format('YYYY-MM-DD HH:mm:ss')
            )
        },
        {
            key: '1', title: '操作',
            fixed: 'right',
            align: 'center',
            render: (record: any) => (
                <Space size="small">
                    {record.stateEnum == 0 ? <>
                        <Button size="small" type="primary" onClick={() => {
                            setEditRecord({
                                viewMode: 1,
                                applyOrderID: record.applyOrderID,
                                applyAllocationID: record.applyAllocationID,
                                singleLimit: record.singleLimit,
                            });
                            setIsOpen(true)
                        }}>编辑</Button>
                        <Button size="small" danger type="primary"
                            onClick={() => { showDeleteConfirm(record) }}>删除</Button>
                        <Button size="small" type='primary'
                            onClick={() => { showSubmitConfirm(record) }}>提交</Button>
                    </> : <></>}
                    <Button size="small" onClick={() => { window.location.href = '/admin/orderdetail?oid=' + record.applyOrderID }}>查看</Button>
                    <Button size="small" type="primary" onClick={() => {
                        window.location.href = '/admin/allocationdetail?oid=' + record.applyAllocationID;
                    }}>查看保单</Button>
                </Space>
            ),
        }]

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除申报订单',
            icon: <ExclamationCircleFilled />,
            content: '申报订单[' + record.applyOrderNO + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { applyOrderID: record.applyOrderID }
                Server.Post(URL.ApplyOrder.DeleteApplyOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    } else {
                        message.warning(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const showSubmitConfirm = (record: any) => {
        confirm({
            title: '提交申报提示',
            icon: <ExclamationCircleFilled />,
            content: '申请订单[' + record.applyOrderNO + ']，确认要提交申报，一旦提交申报将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    applyOrderID: record.applyOrderID,
                    applyOrderNO: record.applyOrderNO
                }
                Server.Post(URL.ApplyOrder.SubmitApplyOrder, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('提交申报成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            state: state,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.ApplyOrder.QueryApplyOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    return <div>
        <div style={{ marginTop: '5px' }}>
            <span>
                <Radio.Group defaultValue={"-1"} value={state} buttonStyle="solid"
                    onChange={(e) => {
                        setState(e.target.value);
                        setPage({ pageIndex: 1, pageSize: page.pageSize })
                    }}>
                    <Radio.Button value={-1}>全部{state == -1 ? '(' + total + ')' : ''}</Radio.Button>
                    <Radio.Button value={0}>待申报{state == 0 ? '(' + total + ')' : ''}</Radio.Button>
                    <Radio.Button value={1}>已申报{state == 1 ? '(' + total + ')' : ''}</Radio.Button>
                </Radio.Group>
            </span>
            <span style={{ float: 'right' }}>
                <Input.Search allowClear style={{ width: '450px' }}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                    onSearch={() => { loadData() }}
                    placeholder="请输入申报单号、目的地、起运地、投保人、被保人、运输单号" />
            </span>
        </div>
        <div style={{ marginTop: '10px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>申报记录列表</span>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns}
                dataSource={dataSource}
                summary={(pageData) => {
                    var totalInsureAmount = 0;
                    pageData.forEach(({ insureAmount }) => {
                        totalInsureAmount += insureAmount;
                    });
                    return <>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1} align={'center'}>
                                <Typography.Text>合计</Typography.Text></Table.Summary.Cell>
                            <Table.Summary.Cell index={1} colSpan={14} align={'center'}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={1} align={'center'}>
                                <Typography.Text>{totalInsureAmount}{'万元'}</Typography.Text>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                }}
                pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
            <ApplyOrderEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
        </div>
    </div>
}
export default ApplyOrder;