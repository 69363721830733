import { Button, Result } from "antd";

const Error404 = () => {
    const onBack = () => {
        window.history.back();
    }

    return <><Result
        status="404"
        title="404"
        subTitle="对不起,您所访问的页面不存在."
        extra={<Button type="primary" onClick={onBack}>返回</Button>}
    /></>
}
export default Error404;